import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	IPageWillMountHandler,
	IPropsStore,
	PageFeatureConfigSymbol,
	Props,
	CurrentRouteInfoSymbol,
} from '@wix/thunderbolt-symbols'
import { DropdownMenuPageConfig } from './types'
import { name } from './symbols'
import { RouteInfo, ICurrentRouteInfo } from 'feature-router'

const updateComponentsProps = (menusIds: Array<string>, routeInfo: RouteInfo, propsStore: IPropsStore) => {
	const updatedProps = menusIds.reduce(
		(updatedObject, menuId) => ({
			...updatedObject,
			...{
				[menuId]: {
					currentPrimaryPageHref: routeInfo.relativeUrl,
				},
			},
		}),
		{}
	)

	propsStore.update(updatedProps)
}

const dropdownMenuFactory = (
	pageFeatureConfig: DropdownMenuPageConfig,
	propsStore: IPropsStore,
	currentRouteInfo: ICurrentRouteInfo
): IPageWillMountHandler => {
	return {
		pageWillMount: async () => {
			updateComponentsProps(pageFeatureConfig.componentsIds, currentRouteInfo.getCurrentRouteInfo()!, propsStore)
		},
	}
}

export const DropdownMenu = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, CurrentRouteInfoSymbol],
	dropdownMenuFactory
)
